import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import InlineFlightCard from '../components/flights/InlineFlightCard';
import { Flight, Airport } from '../ApiClient';
import LandingNavBar from "../components/landing/LandingNavBar";
import AirlineFlightCard from '../components/flights/AirlineFlightCard/AirlineFlightCard';
import FlightSearch from '../components/FlightSearch';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import GhostCard from '../components/flights/GhostCard/GhostCard';
import { 
  ALL_AIRLINES, 
} from '../utils/flightUtils';
import useFlights from '../hooks/useFlights';
import dayjs from 'dayjs';
import { parseSearchParams, SearchParams } from '../utils/paramUtils';

const FlightsPage: React.FC = () => {
  const defaultTheme = createTheme({ palette: { mode: "light" } });
  const location = useLocation();
  const navigate = useNavigate();
  
  const [searchParams, setSearchParams] = useState<SearchParams>(() => {
    const parsedParams = parseSearchParams(location.search);
    return parsedParams;
  });

  console.log('--- FlightsPage Mounted ---');

  useEffect(() => {
    if (!searchParams.from || !searchParams.to || !searchParams.date) {
      // Redirect to Landing Page if any search parameter is missing
      navigate("/", { replace: true });
    }
  }, [searchParams, navigate]);

  // Memoize the Date conversion to prevent unnecessary re-renders
  const searchDate = useMemo(() => {
    if (
      searchParams.date &&
      dayjs.isDayjs(searchParams.date) &&
      searchParams.date.isValid()
    ) {
      const dateObj = searchParams.date.toDate();
      return dateObj;
    }
    
    return undefined;
  }, [searchParams.date]);
  
  // useFlights will handle its own state management and WebSocket connections
  const { flights, loading, error, pendingAirlines } = useFlights(
    searchParams.from?.iataCode,
    searchParams.to?.iataCode,
    searchDate
  );

  const handleSearch = useCallback(async (newFrom: Airport | null, newTo: Airport | null, newDate: Dayjs | null) => {
    if (!newFrom || !newTo || !newDate) {
      console.log('Search not performed due to missing parameters');
      return;
    }

    setSearchParams({
      from: newFrom,
      to: newTo,
      date: newDate
    });

    // Construct query parameters
    const queryParams = new URLSearchParams({
      from: newFrom.iataCode,
      to: newTo.iataCode,
      date: newDate.format('YYYY-MM-DD')
    }).toString();

    navigate(`/livesearch?${queryParams}`, {
      replace: true,
      state: {
        from: newFrom,
        to: newTo,
        date: newDate
      }
    });
  }, [navigate]);

  const initialSearchPerformed = useRef(false);

  useEffect(() => {
    if (
      !initialSearchPerformed.current &&
      searchParams.from &&
      searchParams.to &&
      searchParams.date
    ) {
      handleSearch(searchParams.from, searchParams.to, searchParams.date);
      initialSearchPerformed.current = true;
    }
  }, [searchParams.from, searchParams.to, searchParams.date, handleSearch]);

  const renderContent = (flights: Flight[]) => {
    if (error) return <Typography color="error">Error: {error}</Typography>;

    const flightsByAirline = flights.reduce((acc, flight) => {
      const airline = flight.airline.toLowerCase();
      if (!acc[airline]) {
        acc[airline] = [];
      }
      acc[airline].push(flight);
      return acc;
    }, {} as Record<string, Flight[]>);

    const isStillLoading = loading || Object.values(pendingAirlines).some(Boolean);

    // Only show "no flights" message when we're completely done loading
    if (flights.length === 0 && !isStillLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <Typography variant="h6" sx={{ color: '#012330' }}>
            We couldn't find any flights for your chosen route.
          </Typography>
        </Box>
      );
    }

    // Separate airlines with data and pending airlines
    const airlinesWithData = ALL_AIRLINES.filter(airline => flightsByAirline[airline]?.length > 0);
    const pendingAirlinesList = ALL_AIRLINES.filter(
      airline => !flightsByAirline[airline] && (pendingAirlines[airline] || loading)
    );

    return (
      <>
        {/* Display airlines with actual flight data first */}
        {airlinesWithData.map((airline) => {
          const airlineFlights = flightsByAirline[airline];
          const minPoints = Math.min(
            ...airlineFlights.flatMap(flight => 
              flight.fares.map(fare => fare.points)
            )
          );
          
          return (
            <AirlineFlightCard
              key={airline}
              airlineName={airline}
              pointsRequired={minPoints}
              isExpandedByDefault={true}
            >
              <Grid container spacing={3}>
                {airlineFlights.map((flight) => (
                  <Grid item xs={12} sm={12} md={12} key={flight.id}>
                    <InlineFlightCard flight={flight} />
                  </Grid>
                ))}
              </Grid>
            </AirlineFlightCard>
          );
        })}

        {/* Display ghost cards at the end */}
        {pendingAirlinesList.map((airline) => (
          <GhostCard key={airline} />
        ))}
      </>
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <LandingNavBar />
        <Box component="main" sx={{ flexGrow: 1, pt: 10 }}>
          <Container maxWidth="lg">
            <Box sx={{ mb: 4 }}>
              <FlightSearch 
                onSearch={handleSearch} 
                isLoading={loading} 
                isCompact={true}
                initialFrom={searchParams.from}
                initialTo={searchParams.to}
                initialDate={searchParams.date}
              />
            </Box>
            {renderContent(flights)}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FlightsPage;
