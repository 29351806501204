import React from 'react';
import { Card, styled, Grid } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { Flight } from '../../ApiClient';
import { useState, useRef, useEffect } from 'react';
import { mapFareClass, calculateFlightDuration } from '../../utils/flightUtils';
import { formatAirportTime } from '../../utils/timeUtils';

interface InlineFlightCardProps {
  flight: Flight;
}

type FareClass = 'F' | 'J' | 'W' | 'Y';

const keyframes = `
  @keyframes fadeInBlink {
    0% { opacity: 0; }
    50% { opacity: 1; }
     75% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

const PointsDisplayBlink = styled('span')({
    animation: 'fadeInBlink 1s infinite',
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#061D27',
    marginRight: 0,
    justifyContent: 'center',
    display: 'flex',
    transition: 'color 0.5s ease, transform 0.5s ease',
    
});

const PointsDisplay = styled('span')({
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#061D27',
    marginRight: 0,
    justifyContent: 'center',
    display: 'flex',
    transition: 'color 0.5s ease, transform 0.5s ease',
  });

const StyledCard = styled(Card)(({ theme }) => ({
    fontFamily: 'Montserrat, sans-serif',
    minWidth: "100%",
    height: "6.5rem",
    marginBottom: theme.spacing(1.5),
    background: '#ffffff',
    boxShadow: 'none',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: '#EDF4F7',
    }
}));

const generateAirlineUrl = (flight: Flight, formattedDate: string): string => {
    switch(flight.airline) {
        case 'alaska':
            const alaskaParams = new URLSearchParams({
                'A': '1',
                'O': flight.src,
                'D': flight.dest,
                'OD': formattedDate,
                'OT': 'Anytime',
                'RT': 'false',
                'UPG': 'none',
                'ShoppingMethod': 'onlineaward',
                'awardType': 'MilesOnly'
            });
            return `https://www.alaskaair.com/search/results?${alaskaParams}`;

        case 'american':
            const aaSlice = {
                orig: flight.src,
                origNearby: false,
                dest: flight.dest,
                destNearby: false,
                date: formattedDate
            };
            const aaParams = new URLSearchParams({
                'locale': 'en_US',
                'pax': '1',
                'adult': '1',
                'type': 'OneWay',
                'searchType': 'Award',
                'cabin': '',
                'carriers': 'ALL',
                'travelType': 'personal',
                'slices': JSON.stringify([aaSlice]),
                'pos': 'US',
                'ltier': 'Regular',
                'ttype': 'personal'
            });
            return `https://www.aa.com/booking/search?${aaParams}`;

        case 'delta':
            const deltaParams = new URLSearchParams({
                'cacheKeySuffix': 'T',
                'cartId': '',
                'departure': flight.src,
                'destination': flight.dest,
                'departureDate': formattedDate,
                'origin': flight.src,
                'paxCount': '1',
                'searchType': 'oneWay',
                'tripType': 'ONE_WAY'
            });
            return `https://www.delta.com/flight-search/book-a-flight?${deltaParams}`;
        
        case 'virgin':
            const virginParams = new URLSearchParams({
                origin: flight.src,
                destination: flight.dest,
                departing: formattedDate,
                passengers: 'a1t0c0i0',
                cm_mmc: 'virgin_atlantic'
            });
            return `https://book.virginatlantic.com/flights/search/slice?${virginParams}`;

        default:
            const unitedParams = new URLSearchParams({
                'f': flight.src,
                't': flight.dest,
                'd': formattedDate,
                'sc': '7',
                'st': 'bestmatches',
                'tt': '1',
                'at': '1',
                'taxng': '1',
                'clm': '7',
                'tqp': 'A'
            });
            return `https://www.united.com/en/us/fsr/choose-flights?${unitedParams}`;
    }
};

// const PrimaryFareDisplay = styled('span')({
//   fontSize: '1.5rem',
//   fontWeight: 600,
//   color: '#061D27',
// });

// const SecondaryFareDisplay = styled('span')({
//   fontSize: '1rem',
//   fontWeight: 500,
//   color: '#061D27',
//   opacity: 0.8,
// });

const fareOrder: Record<FareClass, number> = {
    'F': 1,  // First
    'J': 2,  // Business
    'W': 3,  // Premium Economy
    'Y': 4   // Economy
};

interface BlinkingState {
    fareClass: string;
    count: number;
}

const formatPointsToK = (points: number | null | undefined): string => {
    if (points == null || isNaN(points)) return 'N/A';
    const rounded = Math.round(points / 1000);
    return `${rounded}k`;
};

const InlineFlightCard: React.FC<InlineFlightCardProps> = ({ flight }) => {
    const [blinkingStates, setBlinkingStates] = useState<BlinkingState[]>([]);
    const prevFaresRef = useRef(flight.fares);

    useEffect(() => {
        setBlinkingStates(prev => {
            const newBlinkingStates: BlinkingState[] = [];
            
            // Handle new fares (fares that don't exist in prevFaresRef.current)
            flight.fares.forEach(fare => {
                const prevFare = prevFaresRef.current.find(p => p.fareClass === fare.fareClass);
                
                // Case 1: New fare (prevFare doesn't exist)
                if (!prevFare) {
                    newBlinkingStates.push({ fareClass: fare.fareClass, count: 0 });
                }
                // Case 2: Existing fare with different points
                else if (prevFare.points !== fare.points) {
                    newBlinkingStates.push({ fareClass: fare.fareClass, count: 0 });
                }
            });
            
            // Only after comparison, update the ref
            prevFaresRef.current = [...flight.fares];
            
            const result = [
                ...prev.filter(s => s.count < 1), // Keep existing blinks that haven't finished
                ...newBlinkingStates
            ];
            return result;
        });
    }, [flight.fares]);

    useEffect(() => {
        if (blinkingStates.length > 0) {
            const blinkInterval = setInterval(() => {
                setBlinkingStates(prev => 
                    prev.map(state => ({
                        ...state,
                        count: state.count + 1
                    })).filter(state => state.count < 1)
                );
            }, 1000);

            return () => clearInterval(blinkInterval);
        }
    }, [blinkingStates]);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        
        const date = new Date(flight.takeOffDateTime);
        const formattedDate = date.toLocaleDateString('en-CA');
        
        const url = generateAirlineUrl(flight, formattedDate);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const textStyle = {
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#061D27',
    };

    const lineStyle = {
        width: '70%',
        backgroundColor: 'grey',
        height: '1px',
        margin: '0.5rem auto',
        background: 'linear-gradient(to right, grey 20%, lightgrey 20%, lightgrey 80%, grey 80%)',
    };

    return (
        <StyledCard 
            className="inline-flight-card" 
            onClick={handleClick}
        >
            <style>{keyframes}</style>
            <div style={{ display: 'flex' , width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            </div>
            <Grid container style={{ height: '100%' }}>
                {/* Origin Flight Time and Airport*/}
                <Grid
                item
                style={{
                    ...textStyle,
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    paddingLeft: '4rem',
                    width: '14%',
                }}
                >
                    <span style={{ 
                        fontSize: '1.5rem', 
                        fontWeight: 600, 
                        color: '#061D27',
                        whiteSpace: 'nowrap'
                    }}>
                        {formatAirportTime(flight.takeOffDateTime, flight.src)}
                    </span>
                </Grid>

                {/* Flight Duration Line Graphic */}
                <Grid
                item
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '45%',
                    flexDirection: 'column',
                }}
                >
                    <div style={{ display: 'flex', width: '70%', justifyContent: 'space-between' }}>
                        <FlightTakeoffIcon />
                        <span style={{ fontSize: '0.8rem', marginBottom: '0.2rem', color: '#010F16' }}>
                            {calculateFlightDuration(flight.takeOffDateTime, flight.landingDateTime)}
                        </span>
                        <FlightLandIcon />
                    </div>
                    <div style={lineStyle}></div>
                    <div style={{ display: 'flex', width: '70%', justifyContent: 'space-between' }}>
                        <span style={{ fontSize: '0.8rem', color: '#010F16', marginBottom: '0.2rem' }}>
                            {flight.src}
                        </span>
                        <span style={{ fontSize: '0.8rem', color: '#010F16', marginBottom: '0.2rem' }}>
                            {flight.dest}
                        </span>
                    </div>
                </Grid>

                {/* Destination Flight Time and Airport*/}
                <Grid
                item
                style={{
                    ...textStyle,
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    paddingRight: '4rem',
                    width: '14%',
                }}
                >
                    <span style={{ 
                        fontSize: '1.5rem', 
                        fontWeight: 600, 
                        color: '#061D27',
                        whiteSpace: 'nowrap'
                    }}>
                        {formatAirportTime(flight.landingDateTime, flight.dest)}
                    </span>
                </Grid>

                {/* Points and Fare Class*/}
                <Grid
                    item
                    style={{
                        ...textStyle,
                        textAlign: 'left',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left',
                        width: '20%',
                    }}
                    >
                    <div style={{ 
                        height: '80%', 
                        width: '1px', 
                        backgroundColor: 'rgba(6, 29, 39, 0.5)',
                        marginRight: '1rem',
                    }} />
                    <div style={{
                        width: '100%',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: '1rem',
                        marginLeft: '0.5rem'
                    }}>
                        {flight.fares
                            .sort((a, b) => {
                                const aOrder = fareOrder[a.fareClass as FareClass] || 999;
                                const bOrder = fareOrder[b.fareClass as FareClass] || 999;
                                return aOrder - bOrder;
                            })
                            .map((fare) => (
                                <div key={fare.fareClass} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {blinkingStates.some(s => s.fareClass === fare.fareClass) ? (
                                        <PointsDisplayBlink>
                                            {formatPointsToK(fare.points)}
                                        </PointsDisplayBlink>
                                    ) : (
                                        <PointsDisplay>
                                            {formatPointsToK(fare.points)}
                                        </PointsDisplay>
                                    )}
                                    <span style={{ fontSize: '0.8rem', opacity: 0.8 }}>
                                        {mapFareClass(fare.fareClass)}
                                    </span>
                                </div>
                            ))}
                    </div>
                </Grid>
            </Grid>
        </StyledCard>
    );
};

export default InlineFlightCard;
