import { AirportCache } from '../services/AirportCache';

/**
 * Formats a UTC date to local time for a given airport code
 */
export const formatAirportTime = (date: Date | string | null, airportCode: string): string => {
    if (!date) return 'N/A';
    const dateObject = date instanceof Date ? date : new Date(date);
    if (isNaN(dateObject.getTime())) return 'N/A';
    
    try {
        const airport = AirportCache.getAirport(airportCode);
        if (!airport?.timezone) {
            console.warn(`No timezone found for airport ${airportCode}`);
            return 'N/A';
        }

        return new Intl.DateTimeFormat('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            timeZone: airport.timezone,
        }).format(dateObject);
    } catch (error) {
        console.error(`Error formatting time for airport ${airportCode}:`, error);
        return 'N/A';
    }
}; 