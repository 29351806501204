import { Airport } from '../ApiClient';
import { AirportCache } from '../services/AirportCache';
import dayjs from 'dayjs';

export interface SearchParams {
  from: Airport | null;
  to: Airport | null;
  date: dayjs.Dayjs | null;
}

export function parseSearchParams(search: string): SearchParams {
  const params = new URLSearchParams(search);
  
  // Get IATA codes from URL
  const fromIata = params.get('from');
  const toIata = params.get('to');
  const dateStr = params.get('date');

  // Try to construct Airport objects using cache
  const fromAirport = fromIata ? constructAirportFromIata(fromIata) : null;
  const toAirport = toIata ? constructAirportFromIata(toIata) : null;

  return {
    from: fromAirport,
    to: toAirport,
    date: dateStr ? dayjs(dateStr) : null,
  };
}

function constructAirportFromIata(iataCode: string): Airport {
  const cached = AirportCache.getAirport(iataCode);
  
  return {
    id: 0,
    iataCode: iataCode,
    name: cached?.name || '',
    city: cached?.city || '',
    country: cached?.country || '',
    continent: '',
    latitude: cached?.latitude || 0,
    longitude: cached?.longitude || 0,
    timezone: cached?.timezone || 'UTC',
    type: '',
    sizeRank: 0,
  };
} 