import React from 'react';
import { Card } from '@mui/material';
import './GhostCard.css';

const GhostCard: React.FC = () => {
  return (
    <Card className="ghost-card">
      <div className="ghost-card__square" />
      <div className="ghost-card__content">
        <div className="ghost-card__text" />
        <div className="ghost-card__text" />
      </div>
    </Card>
  );
};

export default GhostCard; 