import * as React from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  Snackbar,
  TextField,
  InputAdornment,
  Paper,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { ApiClient, Airport } from "../ApiClient";
import { UserContext } from "../contexts/UserContext";
import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import CalendarDaysIcon from '../assets/CalendarDays.svg';
import { TextFieldProps } from "@mui/material";
import debounce from 'lodash.debounce';
import { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import plane from '../assets/plane.svg';
import LoadingSpinner from './LoadingSpinner';
import { AirportCache } from '../services/AirportCache';

dayjs.extend(isSameOrAfter);

interface CustomInputProps extends Omit<TextFieldProps, 'InputProps'> {
  onClick?: () => void;
  InputProps?: TextFieldProps['InputProps'];
}

const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
  ({ onClick, InputProps, ...props }, ref) => (
    <TextField
      {...props}
      inputRef={ref}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <img 
              src={CalendarDaysIcon} 
              alt="Calendar" 
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
              onMouseDown={(event) => event.preventDefault()} // Prevent text selection
            />
          </InputAdornment>
        ),
        endAdornment: null,
      }}
      inputProps={{
        ...props.inputProps,
        readOnly: true,
        style: {
          fontSize: '1.06rem',
        }
      }}
      onClick={onClick}
      sx={(theme) => ({
        '& .MuiOutlinedInput-root': {
          cursor: 'pointer',
          // MUI Autocomplete default height is 56px (7 * 8px)
          height: theme.spacing(7),
          '& fieldset': {
            borderColor: '#010F16',
            borderRadius: '6px',
          },
          '&:hover fieldset': {
            borderColor: '#061D27',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#010F16',
          },
        },
        '& .MuiInputBase-input': {
          cursor: 'pointer',
          typography: 'h6',
        },
        fontFamily: 'Montserrat, sans-serif',
        mx: 1,
        flex: 1,
        width: '100%',
      })}
    />
  )
);

interface FlightSearchProps {
  onSearch: (from: Airport | null, to: Airport | null, date: Dayjs | null) => Promise<void>;
  isLoading: boolean;
  initialFrom?: Airport | null;
  initialTo?: Airport | null;
  initialDate?: Dayjs | null;
  isCompact?: boolean;
}

export default function FlightSearch({ 
  onSearch, 
  isLoading, 
  initialFrom = null, 
  initialTo = null, 
  initialDate = null,
  isCompact = false // Add default value
}: FlightSearchProps) {
  const { user } = React.useContext(UserContext);

  const [isAlertOpen, setIsAlertOpen] = React.useState<boolean>(false);
  const [alertString, setAlertString] = React.useState<string>("");

  // State for Autocomplete components
  const [fromOptions, setFromOptions] = React.useState<Airport[]>([]);
  const [toOptions, setToOptions] = React.useState<Airport[]>([]);
  const [fromInput, setFromInput] = React.useState<string>(() => {
    if (initialFrom?.city) return `${initialFrom.city} (${initialFrom.iataCode})`;
    else if (initialFrom?.iataCode) return `${initialFrom.iataCode}`;
    else return "";
  });
  const [toInput, setToInput] = React.useState<string>(() => {
    if (initialTo?.city) return `${initialTo.city} (${initialTo.iataCode})`;
    else if (initialTo?.iataCode) return `${initialTo.iataCode}`;
    else return "";
  });
  const [fromValue, setFromValue] = React.useState<Airport | null>(initialFrom);
  const [toValue, setToValue] = React.useState<Airport | null>(initialTo);

  const [date, setDate] = React.useState<Dayjs | null>(initialDate || dayjs());

  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  const [isFromDropdownOpen, setIsFromDropdownOpen] = React.useState(false);
  const [isToDropdownOpen, setIsToDropdownOpen] = React.useState(false);

  const [isFromFocused, setIsFromFocused] = React.useState(false);
  const [isToFocused, setIsToFocused] = React.useState(false);

  // Add new effect for initial airport data fetch
  React.useEffect(() => {
    const fetchInitialAirports = async () => {
      const fetchIfNeeded = async (airport: Airport | null) => {
        if (!airport?.iataCode || airport.city) return null;
        try {
          const results = await ApiClient.searchAirports(airport.iataCode);
          return results.find(a => a.iataCode === airport.iataCode) || null;
        } catch (error) {
          console.error("Error fetching airport:", error);
          return null;
        }
      };

      const [fromResult, toResult] = await Promise.all([
        fetchIfNeeded(initialFrom),
        fetchIfNeeded(initialTo)
      ]);

      if (fromResult) {
        setFromValue(fromResult);
        setFromInput(`${fromResult.city} (${fromResult.iataCode})`);
      }
      if (toResult) {
        setToValue(toResult);
        setToInput(`${toResult.city} (${toResult.iataCode})`);
      }
    };

    fetchInitialAirports();
  }, [initialFrom, initialTo]);

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsAlertOpen(false);
  };

  const isDateValid = React.useMemo(() => {
    return date ? date.isSameOrAfter(dayjs(), 'day') : false;
  }, [date]);

  const handleSearchClick = async () => {
    if (!user) {
      setAlertString("Please log in to search for flights.");
      setIsAlertOpen(true);
      return;
    }

    if (!fromValue || !toValue) {
      setAlertString("Please select both departure and arrival airports.");
      setIsAlertOpen(true);
      return;
    }

    if (fromValue.iataCode === toValue.iataCode) {
      setAlertString("Source and destination cannot be the same.");
      setIsAlertOpen(true);
      return;
    }

    if (!date) {
      setAlertString("Please select a valid date.");
      setIsAlertOpen(true);
      return;
    }

    if (!isDateValid) {
      setAlertString("Please select a future date.");
      setIsAlertOpen(true);
      return;
    }

    try {
      // stop creating monitors on flights search for now
      // await ApiClient.createMonitor(
      //   user.id,
      //   fromValue.iataCode,
      //   toValue.iataCode,
      //   date,
      //   date // Use the same date for both start and end
      // );
      await onSearch(fromValue, toValue, date); // Pass the selected values to onSearch
    } catch (error) {
      console.error("Error creating monitor or searching:", error);
      setAlertString("An error occurred while searching. Please try again.");
      setIsAlertOpen(true);
    }
  };

  const handleSwap = () => {
    const currentFromValue = fromValue;
    const currentToValue = toValue;
    const currentFromInput = fromInput;
    const currentToInput = toInput;

    setFromValue(currentToValue);
    setToValue(currentFromValue);
    setFromInput(currentToInput);
    setToInput(currentFromInput);

    // Clear focus states
    setIsFromFocused(false);
    setIsToFocused(false);
    setIsFromDropdownOpen(false);
    setIsToDropdownOpen(false);
  };

  const handleFromChange = (event: React.SyntheticEvent, newValue: Airport | null) => {
    setFromValue(newValue);
    if (newValue) {
      if (newValue.city) {
        setFromInput(`${newValue.city} (${newValue.iataCode})`);
      } else {
        setFromInput(newValue.iataCode);
      }
    } else {
      setFromInput('');
    }
    setIsFromDropdownOpen(false);
    setIsFromFocused(false);
  };

  const handleToChange = (event: React.SyntheticEvent, newValue: Airport | null) => {
    setToValue(newValue);
    if (newValue) {
      if (newValue.city) {
        setToInput(`${newValue.city} (${newValue.iataCode})`);
      } else {
        setToInput(newValue.iataCode);
      }
    } else {
      setToInput('');
    }
    setIsToDropdownOpen(false);
    setIsToFocused(false);
  };

  // Debounced function to fetch airports based on input
  const fetchFromAirports = React.useMemo(
    () => debounce(async (query: string) => {
      if (query.length < 1) {
        setFromOptions([]);
        setIsFromDropdownOpen(false);
        return;
      }
      try {
        const results = await ApiClient.searchAirports(query);
        setFromOptions(results);
        setIsFromDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching departure airports:", error);
        setFromOptions([]);
        setIsFromDropdownOpen(true);
      }
    }, 100),
    []
  );

  const fetchToAirports = React.useMemo(
    () => debounce(async (query: string) => {
      if (query.length < 1) {
        setToOptions([]);
        setIsToDropdownOpen(false);
        return;
      }
      try {
        const results = await ApiClient.searchAirports(query);
        setToOptions(results);
        setIsToDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching arrival airports:", error);
        setToOptions([]);
        setIsToDropdownOpen(true);
      }
    }, 300),
    []
  );

  // Handle input changes with debouncing
  React.useEffect(() => {
    fetchFromAirports(fromInput);
  }, [fromInput, fetchFromAirports]);

  React.useEffect(() => {
    fetchToAirports(toInput);
  }, [toInput, fetchToAirports]);

  React.useEffect(() => {
    return () => {
      fetchFromAirports.cancel();
      fetchToAirports.cancel();
    };
  }, [fetchFromAirports, fetchToAirports]);

  const getOptionLabel = (option: Airport | null) => {
    if (!option) return '';
    
    if (option.city) {
      return `${option.city} (${option.iataCode})`;
    }
    
    // Try to get data from cache
    const cached = AirportCache.getAirport(option.iataCode);
    if (cached && cached.city) {
      return `${cached.city} (${option.iataCode})`;
    }
    
    // Fallback to just the IATA code
    return option.iataCode;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={4000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" variant="filled" onClose={handleAlertClose}>
          {alertString}
        </Alert>
      </Snackbar>

      <Container maxWidth={isCompact ? "md" : "lg"} sx={{ mt: 4 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '8px',
            padding: '12px 8px',
            ...(isCompact ? {
              maxWidth: '800px', // Reduce the max-width when compact
              margin: '0 auto', // Center the container
            } : {
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
              },
            }),
            flexWrap: 'wrap',
          }}
        >
          {/* From Autocomplete */}
          <Autocomplete
            id="select-src"
            value={fromValue || undefined}
            onChange={handleFromChange}
            inputValue={fromInput}
            PaperComponent={({ children }) => (
              <Paper
                sx={{
                  width: '125%', // 20% wider
                  transform: 'translateX(0)', // Align with left edge
                }}
              >
                {children}
              </Paper>
            )}
            onInputChange={(event, newInputValue, reason: AutocompleteInputChangeReason) => {
              if (reason === 'input') {
                setFromInput(newInputValue);
                setIsFromFocused(true);
                setIsFromDropdownOpen(newInputValue.length > 0);
              }
            }}
            onBlur={() => {
              setIsFromFocused(false);
              setIsFromDropdownOpen(false);
            }}
            options={fromOptions}
            disableClearable
            forcePopupIcon={false}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option: Airport) => (
              <li {...props} key={option.iataCode}>
                <Box>
                  <Box>{`${option.name} (${option.iataCode})`}</Box>
                  <Box sx={{ fontSize: '0.8em', color: 'text.secondary' }}>
                    {`${option.city}, ${option.country}`}
                  </Box>
                </Box>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="From"
                variant="outlined"
                aria-label="Select departure airport"
                InputProps={{
                  ...params.InputProps,
                  style: { fontWeight: 'bold' }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: '#010F16', borderRadius: '6px' },
                    '&:hover fieldset': { borderColor: '#0B5369' },
                    '&.Mui-focused fieldset': { borderColor: '#010F16' },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#010F16',
                    '&.Mui-focused': { color: '#010F16' },
                  },
                  fontFamily: 'Montserrat, sans-serif',
                }}
              />
            )}
            sx={{
              flex: 1,
              mx: 1,
              minWidth: isCompact ? '180px' : '200px', // Reduce by 20%
              maxWidth: isCompact ? '240px' : '300px', // Reduce by 20%
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                top: '25%',
                right: 0,
                height: '50%',
                width: '1px',
                backgroundColor: '#010F16',
                zIndex: 2,
              },
            }}
            open={isFromDropdownOpen && isFromFocused}
            noOptionsText={isFromFocused && fromInput.length > 0 ? "No airports found" : ""}
            isOptionEqualToValue={(option, value) => option.iataCode === value.iataCode}
          />

          <Box
            sx={{
              width: '35px',
              height: '35px',
              backgroundColor: 'transparent',
              backdropFilter: 'blur(16px)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(1, 15, 22, 0.05)',
              },
              transition: 'background-color 0.1s ease-in-out',
              position: 'relative',
              zIndex: 3,
              border: '1px solid #010F16',
              margin: '0 -20px',
            }}
            onClick={handleSwap}
            aria-label="swap airports"
          >
            <SwapHorizIcon sx={{ color: '#010F16', fontSize: '24px' }} />
          </Box>
          
          {/* To Autocomplete */}
          <Autocomplete
            id="select-dest"
            value={toValue || undefined}
            onChange={handleToChange}
            inputValue={toInput}
            PaperComponent={({ children }) => (
              <Paper
                sx={{
                  width: '120%', // 20% wider
                  transform: 'translateX(0)', // Align with left edge
                }}
              >
                {children}
              </Paper>
            )}
            onInputChange={(event, newInputValue, reason: AutocompleteInputChangeReason) => {
              if (reason === 'input') {
                setToInput(newInputValue);
                setIsToFocused(true);
                setIsToDropdownOpen(newInputValue.length > 0);
              }
            }}
            onBlur={() => {
              setIsToFocused(false);
              setIsToDropdownOpen(false);
            }}
            options={toOptions}
            disableClearable
            forcePopupIcon={false}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option: Airport) => (
              <li {...props} key={option.iataCode}>
                <Box>
                  <Box>{`${option.name} (${option.iataCode})`}</Box>
                  <Box sx={{ fontSize: '0.8em', color: 'text.secondary' }}>
                    {`${option.city}, ${option.country}`}
                  </Box>
                </Box>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="To"
                variant="outlined"
                aria-label="Select arrival airport"
                InputProps={{
                  ...params.InputProps,
                  style: { fontWeight: 'bold' }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: '#010F16', borderRadius: '6px' },
                    '&:hover fieldset': { borderColor: '#0B5369' },
                    '&.Mui-focused fieldset': { borderColor: '#010F16' },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#010F16',
                    '&.Mui-focused': { color: '#010F16' },
                  },
                  fontFamily: 'Montserrat, sans-serif',
                  marginLeft: '5px',
                }}
              />
            )}
            sx={{
              flex: 1,
              mx: 1,
              minWidth: isCompact ? '160px' : '200px', // Reduce by 20%
              maxWidth: isCompact ? '240px' : '300px', // Reduce by 20%
              position: 'relative',
              marginLeft: '5px',
            }}
            open={isToDropdownOpen && isToFocused}
            noOptionsText={isToFocused && toInput.length > 0 ? "No airports found" : ""}
            isOptionEqualToValue={(option, value) => option.iataCode === value.iataCode}
          />

          <DatePicker
            open={isDatePickerOpen}
            onOpen={() => setIsDatePickerOpen(true)}
            onClose={() => setIsDatePickerOpen(false)}
            value={date}
            onChange={(newValue) => setDate(newValue)}
            slots={{
              textField: CustomInput,
            }}
            slotProps={{
              textField: {
                onClick: () => setIsDatePickerOpen(true),
              },
            }}
            format="MMM D, YYYY"
            minDate={dayjs()}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: '#010F16', borderRadius: '6px' },
                '&:hover fieldset': { borderColor: '#061D27' },
                '&.Mui-focused fieldset': { borderColor: '#010F16' },
              },
              fontFamily: 'Montserrat, sans-serif',
              flex: 1,
              mx: 1,
              maxWidth: isCompact ? '150px' : '250px',
            }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchClick}
            disabled={isLoading}
            sx={{
              backgroundColor: '#010F16',
              color: '#fff',
              padding: '12px 24px',
              borderRadius: '6px',
              textTransform: 'none',
              fontWeight: 600,
              fontFamily: 'Montserrat, sans-serif',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: '#0B5369',
                transform: 'translateY(-1px)',
                boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
              },
              mx: 'auto',
              my: 1,
              minWidth: isCompact ? undefined : '150px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {!isCompact && "Find Flights"}
                <div style={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  padding: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <img src={plane} alt="Plane" style={{ width: '20px', height: '20px' }} />
                </div>
              </>
            )}
          </Button>
        </Box>
      </Container>
    </LocalizationProvider>
  );
}
