interface CachedAirport {
  iataCode: string;
  name: string;
  city: string;
  country: string;
  latitude?: number;
  longitude?: number;
  timezone: string;
}

export class AirportCache {
  private static CACHE_KEY = 'airport_cache';
  private static CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours

  static getAirport(iataCode: string): CachedAirport | null {
    try {
      const cache = localStorage.getItem(this.CACHE_KEY);
      if (!cache) return null;

      const { airports, timestamp } = JSON.parse(cache);
      
      // Check cache expiry
      if (Date.now() - timestamp > this.CACHE_EXPIRY) {
        localStorage.removeItem(this.CACHE_KEY);
        return null;
      }

      return airports[iataCode] || null;
    } catch (error) {
      console.error('Error reading from airport cache:', error);
      return null;
    }
  }

  static cacheAirport(airport: CachedAirport): void {
    try {
      const cache = localStorage.getItem(this.CACHE_KEY);
      const existingData = cache 
        ? JSON.parse(cache)
        : { airports: {}, timestamp: Date.now() };

      existingData.airports[airport.iataCode] = airport;
      
      localStorage.setItem(this.CACHE_KEY, JSON.stringify(existingData));
    } catch (error) {
      console.error('Error writing to airport cache:', error);
    }
  }
} 