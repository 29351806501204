import { useState, useEffect, useCallback, useRef } from 'react';
import { ApiClient, Flight, FareOption } from '../ApiClient';
import { 
  ALL_AIRLINES, 
  AIRLINE_TIMEOUT_DURATION, 
  initializeAirlineStatuses 
} from '../utils/flightUtils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const useFlights = (src?: string, dest?: string, date?: Date | undefined) => {
  const [flights, setFlights] = useState<Flight[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pendingAirlines, setPendingAirlines] = useState<Record<string, boolean>>({});
  const airlineTimeouts = useRef<Record<string, NodeJS.Timeout>>({});

  const fetchFlights = useCallback(async (src: string, dest: string, date: Date) => {
    try {
      // Initialize pending airlines immediately
      setPendingAirlines(initializeAirlineStatuses());
      setLoading(true);
      setError(null);

      // Clear previous flights and timeouts first
      setFlights([]);
      Object.values(airlineTimeouts.current).forEach(timeout => clearTimeout(timeout));
      airlineTimeouts.current = {};

      const fetchedFlights = await ApiClient.getLiveSearchFlights(src, dest, date);
      console.log('Fetched live search flights:', fetchedFlights);
      setFlights(fetchedFlights);

      // Set timeouts for each airline
      ALL_AIRLINES.forEach((airline) => {
        if (!fetchedFlights.some(flight => flight.airline.toLowerCase() === airline.toLowerCase())) {
          const timeout = setTimeout(() => {
            setPendingAirlines((prev) => ({
              ...prev,
              [airline]: false,
            }));
          }, AIRLINE_TIMEOUT_DURATION);
          airlineTimeouts.current[airline] = timeout;
        } else {
          // Airline has already returned data, so it's not pending
          setPendingAirlines((prev) => ({
            ...prev,
            [airline]: false,
          }));
        }
      });

      // Join the live search room after fetching initial flights
      ApiClient.joinLiveSearchRoom(src, dest, date);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  }, []);

// In useFlights.ts
const handleFlightUpdate = useCallback((data: any) => {
  const updatedFlight = data.flight;
  console.log('Received new flight data:', data);

    // Convert both dates to UTC and compare the date parts
    const flightDateUTC = dayjs(updatedFlight.takeOffDateTime).utc().format('YYYY-MM-DD');
    const searchDateUTC = dayjs(date).utc().format('YYYY-MM-DD');

  // Check if updatedFlight matches current src, dest, date
  if (
    updatedFlight.src !== src ||
    updatedFlight.dest !== dest ||
    flightDateUTC !== searchDateUTC
  ) {
    console.log('Received flight update for different search parameters, ignoring.');
    console.log('src:', updatedFlight.src, 'dest:', updatedFlight.dest, 'date:', updatedFlight.takeOffDateTime);
    console.log('date:', dayjs(updatedFlight.takeOffDateTime).format('YYYY-MM-DD'), '!==', dayjs(date).format('YYYY-MM-DD'));

    return;
  }

  setFlights((prevFlights) => {
    if (data.operation === 'INSERT') {
      // Check if flight already exists using the same criteria as UPDATE
      const existingFlightIndex = prevFlights.findIndex((flight) => 
        flight.airline === updatedFlight.airline &&
        flight.src === updatedFlight.src &&
        flight.dest === updatedFlight.dest &&
        dayjs(flight.takeOffDateTime).isSame(dayjs(updatedFlight.takeOffDateTime))
      );

      if (existingFlightIndex !== -1) {
        // If flight exists, treat it as an UPDATE
        const newFlights = [...prevFlights];
        newFlights[existingFlightIndex] = {
          ...newFlights[existingFlightIndex],
          fares: updatedFlight.fares
        };
        return newFlights;
      }

      // Only add if it's truly a new flight
      return [...prevFlights, updatedFlight];
    } else if (data.operation === 'UPDATE') {
      console.log('Processing UPDATE operation for flight:', {
        airline: updatedFlight.airline,
        src: updatedFlight.src,
        dest: updatedFlight.dest,
        takeoff: updatedFlight.takeOffDateTime,
        incomingFares: updatedFlight.fares
      });

      const index = prevFlights.findIndex((flight) => 
        flight.airline === updatedFlight.airline &&
        flight.src === updatedFlight.src &&
        flight.dest === updatedFlight.dest &&
        dayjs(flight.takeOffDateTime).isSame(dayjs(updatedFlight.takeOffDateTime))
      );

      if (index !== -1) {
        console.log('Found matching flight at index:', index, {
          existingFares: prevFlights[index].fares
        });
        const newFlights = [...prevFlights];
        // Update only the matching fare class, preserve others
        const updatedFares = [...prevFlights[index].fares];
        updatedFlight.fares.forEach((newFare: FareOption) => {
          const fareIndex = updatedFares.findIndex(f => f.fareClass === newFare.fareClass);
          if (fareIndex !== -1) {
            updatedFares[fareIndex] = newFare;
          } else {
            updatedFares.push(newFare);
          }
        });

        newFlights[index] = {
          ...newFlights[index],
          fares: updatedFares
        };
        return newFlights;
      }
    }
    return prevFlights;
  });

  const airline = updatedFlight.airline.toLowerCase();
  if (airline) {
    setPendingAirlines((prev) => ({
      ...prev,
      [airline]: false
    }));

    if (airlineTimeouts.current[airline]) {
      clearTimeout(airlineTimeouts.current[airline]);
      delete airlineTimeouts.current[airline];
    }
  }
}, [src, dest, date]);


  useEffect(() => {
    ApiClient.initializeSocket();
    ApiClient.subscribeToFlightUpdates(handleFlightUpdate);

    return () => {
      try {
        ApiClient.unsubscribeFromFlightUpdates();
        ApiClient.disconnectSocket();
      } catch (error) {
        console.error('Error during socket cleanup:', error);
      }
      Object.values(airlineTimeouts.current).forEach(timeout => clearTimeout(timeout));
    };
  }, [handleFlightUpdate]);

  useEffect(() => {
    if (!src || !dest || !date) {
      return;
    }

    fetchFlights(src, dest, date);
  }, [src, dest, date, fetchFlights]);

  return { flights, loading, error, setError, pendingAirlines };
};

export default useFlights; 