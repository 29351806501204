import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { getAirlineLogo, getAirlineRewardsProgram } from '../../../utils/logoUtils';
import { getAirlineCreditCards } from '../../../utils/creditCardUtils';
import { getNormalizedAirlineName } from '../../../utils/flightUtils';
import { AirlineFlightCardProps } from './AirlineFlightCard.types';
import { formatPoints } from './AirlineFlightCard.utils';
import './AirlineFlightCard.css';

const AirlineFlightCard: React.FC<AirlineFlightCardProps> = ({
  airlineName,
  pointsRequired,
  onExpand,
  onCollapse,
  isExpandedByDefault = false,
  children
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
  const [blinking, setBlinking] = useState(false);
  const [blinkCount, setBlinkCount] = useState(0);
  const [displayPointsRequired, setDisplayPointsRequired] = useState(pointsRequired);
  const prevPointsRequiredRef = useRef(pointsRequired);

  const creditCards = getAirlineCreditCards(airlineName);
  const displayedCards = creditCards.slice(0, 3);
  const hiddenCards = creditCards.slice(3);

  useEffect(() => {
    if (pointsRequired !== prevPointsRequiredRef.current) {
      setDisplayPointsRequired(pointsRequired);
      setBlinkCount(0);
      setBlinking(true);
      prevPointsRequiredRef.current = pointsRequired;
    }
  }, [pointsRequired]);

  useEffect(() => {
    if (blinking) {
      const blinkInterval = setInterval(() => {
        setBlinkCount((prev) => prev + 1);
      }, 1000);

      if (blinkCount >= 1) {
        clearInterval(blinkInterval);
        setBlinking(false);
      }

      return () => clearInterval(blinkInterval);
    }
  }, [blinking, blinkCount]);

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
    if (!isExpanded && onExpand) onExpand();
    if (isExpanded && onCollapse) onCollapse();
  };

  const renderTopSection = () => (
    <Box 
      onClick={handleToggle}
      sx={{
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        margin: '-16px',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: '#EDF4F7',
        },
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        {/* Airline Logo */}
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <img 
            className="airline-card__logo"
            src={getAirlineLogo(airlineName)} 
            alt={`${airlineName} Logo`}
          />
        </Grid>

        {/* Airline Name */}
        <Grid item xs>
          <Typography className="airline-card__name">
            {getNormalizedAirlineName(airlineName)} Airlines
          </Typography>
        </Grid>

        {/* Points Required, Credit Cards, and Rewards Program */}
        <Grid item xs={6}>
          <Box display="flex" alignItems="center">
            <div className="airline-card__center-container">
              <div className="airline-card__credit-cards">
                {displayedCards.map((card, index) => (
                  <Tooltip key={index} title={card.name}>
                    <div className="airline-card__credit-card" style={{ zIndex: 10 - index }}>
                      <img 
                        className="airline-card__credit-card-image"
                        src={card.logo} 
                        alt={card.name}
                      />
                    </div>
                  </Tooltip>
                ))}
                {hiddenCards.length > 0 && (
                  <Tooltip title={hiddenCards.map(card => card.name).join(', ')}>
                    <div className="airline-card__more-cards">
                      +{hiddenCards.length}
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className="airline-card__arrow-container">
                <ArrowRightAltIcon />
              </div>
              <Tooltip title={`${getNormalizedAirlineName(airlineName)} Rewards Program`}>
                <img 
                  className="airline-card__rewards-logo"
                  src={getAirlineRewardsProgram(airlineName)} 
                  alt={`${airlineName} Rewards Program`}
                  style={{ width: airlineName.includes('american') ? '130px' : 
                           airlineName.includes('united') || airlineName.includes('delta') ? '110px' : 
                           airlineName.includes('alaska') ? '90px' : 'auto',
                           maxHeight: '50px' }}
                />
              </Tooltip>
            </div>
            <div className="airline-card__points-container">
              <span className={blinking ? 'airline-card__points--blinking' : 'airline-card__points'}>
                {formatPoints(displayPointsRequired)}+
              </span>
            </div>
          </Box>
        </Grid>

        {/* Expand/Collapse Icon */}
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            className={`airline-card__expand-icon ${isExpanded ? 'airline-card__expand-icon--expanded' : ''}`}
            aria-label={isExpanded ? 'Collapse' : 'Expand'}
            disableRipple
            onClick={handleToggle}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Card
      elevation={0}
      sx={{
        width: '100%',
        margin: '16px 0',
        borderRadius: '8px',
        boxShadow: 'none',
        border: '1px solid rgba(207, 229, 236, 0.8)',
      }}
    >
      <CardContent
        sx={{
          padding: '16px',
          height: 'auto',
          minHeight: 0,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          '&:last-child': {
            paddingBottom: '16px',
          },
        }}
      >
        {renderTopSection()}
        {isExpanded && (
          <Box mt={2}>
            {children}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default AirlineFlightCard; 