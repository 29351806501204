import * as React from "react";
import "./App.css";
import { Outlet } from "react-router";
import UserProvider from "./contexts/UserContext";
import { ApiClient } from "./ApiClient";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  ApiClient.initializeSocket();
  return (
    <div className="App">
      <ErrorBoundary>
        <UserProvider>
          <Outlet />
        </UserProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
