import { CreditCard } from '../../../types/common';

/**
 * Formats point values to K format (e.g., 25000 -> "25K")
 */
export const formatPoints = (points: number): string => {
  const pointsInK = Math.floor(points / 1000);
  return `${pointsInK}K`;
};

/**
 * Gets custom width for airline rewards program logos
 */
export const getRewardsProgramLogoWidth = (airlineName: string): string => {
  const airlineNameLower = airlineName.toLowerCase();
  
  if (airlineNameLower.includes('american')) return '130px';
  if (airlineNameLower.includes('united') || airlineNameLower.includes('delta')) return '110px';
  if (airlineNameLower.includes('alaska')) return '90px';
  return 'auto';
};

/**
 * Constants for animation and styling
 */
export const ANIMATION_CONSTANTS = {
  BLINK_DURATION: 1000, // ms
  MAX_BLINK_COUNT: 1,
  CREDIT_CARDS_TO_DISPLAY: 3,
} as const;

/**
 * Constants for layout
 */
export const LAYOUT_CONSTANTS = {
  LOGO_SIZE: '60px',
  CREDIT_CARD_SIZE: '30px',
  CREDIT_CARD_OVERLAP: '-10px',
  ARROW_CONTAINER_WIDTH: '24px',
  CENTER_CONTAINER_OFFSET: '-50px',
} as const;

/**
 * Calculates z-index for overlapping credit cards
 */
export const getCreditCardZIndex = (index: number, baseZIndex: number = 10): number => {
  return baseZIndex - index;
};

/**
 * Gets tooltip text for hidden credit cards
 */
export const getHiddenCardsTooltip = (hiddenCards: CreditCard[]): string => {
  return hiddenCards.map(card => card.name).join(', ');
};

/**
 * Formats airline name for display
 */
export const formatAirlineName = (airlineName: string): string => {
  return `${airlineName} Airlines`;
};

/**
 * Validates points value
 */
export const isValidPointsValue = (points: number): boolean => {
  return points > 0 && Number.isFinite(points);
};

/**
 * Gets aria-label for expand/collapse button
 */
export const getExpandButtonAriaLabel = (isExpanded: boolean): string => {
  return isExpanded ? 'Collapse' : 'Expand';
}; 