import chaseLogo from '../assets/credit_cards/chase.png';
import amexLogo from '../assets/credit_cards/amex.png';
import bonvoyLogo from '../assets/credit_cards/bonvoy.png';
import capitalOneLogo from '../assets/credit_cards/cap1.png';
import biltLogo from '../assets/credit_cards/bilt.png';
import citiLogo from '../assets/credit_cards/citi.png';

interface CreditCard {
  name: string;
  logo: string;
}

const CREDIT_CARDS = {
  CHASE: { name: 'Chase Ultimate Rewards', logo: chaseLogo },
  AMEX: { name: 'American Express Membership Rewards', logo: amexLogo },
  BONVOY: { name: 'Marriott Bonvoy Rewards', logo: bonvoyLogo },
  CAPITAL_ONE: { name: 'Capital One Rewards', logo: capitalOneLogo },
  BILT: { name: 'Bilt Credit Card', logo: biltLogo },
  CITI: { name: 'Citi ThankYou Rewards', logo: citiLogo },
} as const;

const airlineCreditCards: Record<string, CreditCard[]> = {
  'delta': [
    CREDIT_CARDS.AMEX,
    CREDIT_CARDS.CAPITAL_ONE,
    CREDIT_CARDS.BONVOY,
  ],
  'united': [
    CREDIT_CARDS.CHASE,
    CREDIT_CARDS.BILT,
    CREDIT_CARDS.BONVOY,
  ],
  'alaska': [
    CREDIT_CARDS.BILT,
    CREDIT_CARDS.BONVOY,
  ],
  'american': [
    CREDIT_CARDS.BONVOY,
  ],
  'virgin': [
    CREDIT_CARDS.CHASE,
    CREDIT_CARDS.CAPITAL_ONE,
    CREDIT_CARDS.BONVOY,
    CREDIT_CARDS.CITI
  ]
};

export function getAirlineCreditCards(airlineName: string): CreditCard[] {
  const normalizedName = airlineName.toLowerCase().trim();
  return airlineCreditCards[normalizedName] || [];
}

// Export constants if needed elsewhere
export { CREDIT_CARDS };