// Create an axios based API client

import axios, { AxiosError, HttpStatusCode } from "axios";
import serverUrl from "./config";
import dayjs from "dayjs";
import { User } from "./contexts/UserContext";
import { io, Socket } from 'socket.io-client';
import { AirportCache } from './services/AirportCache';

const apiClient = axios.create({
  baseURL: serverUrl + "/api",
  withCredentials: true,
});

export type ValidRoute = {
  id: number;
  src: string;
  dest: string;
};

export type Monitor = {
  id: number;
  userId: number;
  validRouteId: number;
  pointsThreshold: number;
  startDate: string;
  endDate: string;
  createdAt: string;
};

export interface FareOption {
  fareClass: 'F' | 'J' | 'W' | 'Y';
  points: number;
}

export interface Flight {
  id: number;
  airline: string;
  src: string;
  dest: string;
  takeOffDateTime: Date;
  landingDateTime: Date | null;
  fares: FareOption[];
}

export interface Airport {
  id: number;
  iataCode: string;
  name: string;
  city: string;
  country: string;
  continent: string;
  latitude: number;
  longitude: number;
  type: string;
  sizeRank: number;
  timezone: string;
}

export class ApiClient {
  private static socket: Socket | null = null;
  private static flightMap = new Map<string, Flight>();

  static async getUser(): Promise<User | undefined> {
    try {
      return (await apiClient.get("/")).data.user;
    } catch (error) {
      return undefined;
    }
  }

  static async logout(): Promise<void> {
    try {
      await apiClient.post("/auth/logout");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }

  static async searchAirports(query: string): Promise<Airport[]> {
    try {
      const response = await apiClient.get(`/airports`, {
        params: { search: query },
      });
      console.log("API Response for searchAirports:", response.data);
      
      const { airports } = response.data;
      
      // Cache each airport with coordinates
      airports.forEach((airport: Airport) => {
        AirportCache.cacheAirport({
          iataCode: airport.iataCode,
          name: airport.name,
          city: airport.city,
          country: airport.country,
          latitude: airport.latitude,
          longitude: airport.longitude,
          timezone: airport.timezone
        });
      });
      
      return airports;
    } catch (error) {
      console.error("Error searching airports:", error);
      return [];
    }
  }

  static async createMonitor(
    userId: number,
    src: string,
    dest: string,
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
  ): Promise<Monitor | undefined> {
    try {
      return (
        await apiClient.post("/monitors", {
          userId: userId,
          src,
          dest,
          pointsThreshold: 100000,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        })
      ).data.monitor;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  static async getFlights(): Promise<Flight[]> {
    try {
      const response = await apiClient.get("/livesearch");
      if (response.status === HttpStatusCode.Ok) {
        return response.data.flights || [];
      } else {
        throw new Error(`Failed to retrieve flights - ${response.status}`);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error("Error fetching flights:", error.response?.data || error.message);
      } else {
        console.error("Error fetching flights:", error);
      }
      throw error; // Re-throw to allow component-level handling
    }
  }

  static async getLiveSearchFlights(src: string, dest: string, takeOffDateTime: Date): Promise<Flight[]> {
    try {
      const response = await apiClient.get("/livesearch", {
        params: {
          src,
          dest,
          takeOffDateTime: takeOffDateTime.toISOString(),
        },
      });
      if (response.status === HttpStatusCode.Ok) {
        console.log("flight data: ", response.data.flights);
        console.log('Initial flight IDs:', response.data.flights.map((f: any) => ({ 
          rawId: f.id, 
          type: typeof f.id 
        })));
        return (response.data.flights || []).map((flight: any) => ({
          ...flight,
          id: Number(flight.id),
          takeOffDateTime: new Date(flight.takeOffDateTime),
          landingDateTime: flight.landingDateTime ? new Date(flight.landingDateTime) : null,
          fares: flight.fares || []
        }));
      } else {
        throw new Error(`Failed to retrieve live search flights - ${response.status}`);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error("Error fetching live search flights:", error.response?.data || error.message);
      } else {
        console.error("Error fetching live search flights:", error);
      }
      throw error;
    }
  }

  static initializeSocket() {
    if (this.socket) {
      console.warn('Socket already initialized.');
      return;
    }

    this.socket = io(serverUrl, {
      path: '/api/ws/flights'
    });
    
    this.socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });
  }

  static disconnectSocket() {
    if (!this.socket) {
      console.warn('Socket is not connected.');
      return;
    }

    this.flightMap.clear();  // Add this line to clean up the map
    this.socket.disconnect();
    this.socket = null;
    console.log('Socket disconnected and cleaned up.');
  }

  static joinLiveSearchRoom(src: string, dest: string, takeOffDateTime: Date) {
    if (!this.socket) {
      console.error('Socket not initialized. Call initializeSocket() first.');
      return;
    }

    const payload = {
      src,
      dest,
      takeOffDateTime: takeOffDateTime.toISOString().split('T')[0], // Only send the date part
    };

    this.socket.emit('joinLiveSearchRoom', payload);
    console.log('Joining live search room:', payload);
  }

  static subscribeToFlightUpdates(callback: (data: { operation: 'INSERT' | 'UPDATE', flight: Flight }) => void) {
    if (!this.socket) {
      console.error('Socket not initialized. Call initializeSocket() first.');
      return;
    }

    this.socket.on('flightUpdate', (update: any) => {
      const flight = {
        ...update.flight,
        takeOffDateTime: new Date(update.flight.takeOffDateTime),
        landingDateTime: update.flight.landingDateTime ? new Date(update.flight.landingDateTime) : null
      };

      const key = `${flight.airline}-${flight.src}-${flight.dest}-${flight.takeOffDateTime.toISOString()}`;

      if (!this.flightMap.has(key)) {
        this.flightMap.set(key, {
          ...flight,
          fares: []
        });
      }

      const existingFlight = this.flightMap.get(key)!;
      const existingFareIndex = existingFlight.fares.findIndex(
        fare => fare.fareClass === update.flight.fareClass
      );

      const newFare: FareOption = {
        fareClass: update.flight.fareClass as 'F' | 'J' | 'W' | 'Y',
        points: update.flight.points
      };

      if (existingFareIndex > -1) {
        existingFlight.fares[existingFareIndex] = newFare;
      } else {
        existingFlight.fares.push(newFare);
      }

      callback({
        operation: update.operation,
        flight: existingFlight
      });

    });
  }

  static unsubscribeFromFlightUpdates() {
    if (!this.socket) {
      console.error('Socket not initialized. Call initializeSocket() first.');
      return;
    }

    this.socket.off('flightUpdate');
  }
}
